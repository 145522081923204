<template>
  <!--begin::Row-->
  <div class="row g-5 g-xl-8">
    <div class="col-xl-12">
      <div class="card mb-xl-8">
        <!--begin::Body-->
        <div class="card-body">
          <!--begin::Heading-->
          <div class="text-center">
            <!--begin::Title-->
            <div class="card-label fw-bolder fs-3 mb-3">
              {{ $t("ogza.createCompany.title") }}
            </div>
            <!--end::Title-->
          </div>
          <!--end::Heading-->
          <!--begin::Form-->
          <Form
            id="kt_account_profile_details_form"
            class="form"
            :validation-schema="addCompanyForm"
          >
            <!--begin::Card body-->
            <div class="card-body p-9 ps-6 pb-1">
              <div class="row mb-0">
                <div class="col-md-6">
                  <!--begin::Input group-->
                  <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label
                      class="form-label fs-6 fw-bolder text-dark required"
                      >{{ $t("ogza.createCompany.companyName") }}</label
                    >
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      name="companyName"
                      autocomplete="off"
                      :placeholder="$t('ogza.createCompany.enterCompanyName')"
                    />
                    <!--end::Input-->
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="companyName" />
                      </div>
                    </div>
                  </div>
                  <!--end::Input group-->
                </div>
                <div class="col-md-6">
                  <!--begin::Input group-->
                  <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label
                      class="form-label fs-6 fw-bolder text-dark required"
                      >{{ $t("ogza.createCompany.companyCategory") }}</label
                    >
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      name="companyCategory"
                      autocomplete="off"
                      :placeholder="
                        $t('ogza.createCompany.enterCompanyCategory')
                      "
                    />
                    <!--end::Input-->
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="companyCategory" />
                      </div>
                    </div>
                  </div>
                  <!--end::Input group-->
                </div>
              </div>
              <div class="row mb-0">
                <div class="col-md-6">
                  <!--begin::Input group-->
                  <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label
                      class="form-label fs-6 fw-bolder text-dark required"
                      >{{ $t("ogza.createCompany.managerName") }}</label
                    >
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      name="managerName"
                      autocomplete="off"
                      :placeholder="$t('ogza.createCompany.enterManagerName')"
                    />
                    <!--end::Input-->
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="managerName" />
                      </div>
                    </div>
                  </div>
                  <!--end::Input group-->
                </div>
                <div class="col-md-6">
                  <!--begin::Input group-->
                  <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label
                      class="form-label fs-6 fw-bolder text-dark required"
                      >{{ $t("ogza.createCompany.managerSurname") }}</label
                    >
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      name="managerSurname"
                      autocomplete="off"
                      :placeholder="
                        $t('ogza.createCompany.enterManagerSurname')
                      "
                    />
                    <!--end::Input-->
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="managerSurname" />
                      </div>
                    </div>
                  </div>
                  <!--end::Input group-->
                </div>
              </div>
              <div class="row mb-0">
                <div class="col-md-3">
                  <!--begin::Input group-->
                  <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label class="form-label fs-6 fw-bolder text-dark required">
                      {{ $t("ogza.createCompany.email") }}</label
                    >
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      name="email"
                      autocomplete="off"
                      :placeholder="$t('ogza.createCompany.enterEmail')"
                    />
                    <!--end::Input-->
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="email" />
                      </div>
                    </div>
                  </div>
                  <!--end::Input group-->
                </div>
                <div class="col-md-3">
                  <!--begin::Input group-->
                  <div class="fv-row mb-10">
                    <label
                      class="form-label fs-6 fw-bolder text-dark required"
                      >{{ $t("login.country") }}</label
                    >
                    <div class="d-flex align-items-center">
                      <select
                        class="form-select form-select-lg form-select-solid"
                        required
                      >
                        <option
                          v-for="country in countryList"
                          :key="country.country.id"
                          :value="country.country.id"
                        >
                          <img
                            :src="country.country.flagUrl"
                            :alt="country.country.name"
                            class="me-2"
                            width="24"
                            height="24"
                          />{{ country.country.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <!--end::Input group-->
                </div>
                <div class="col-md-3">
                  <!--begin::Input group-->
                  <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label
                      class="form-label fs-6 fw-bolder text-dark required"
                      >{{ $t("ogza.createCompany.phone") }}</label
                    >
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      name="phone"
                      autocomplete="off"
                      placeholder="(###) ###-##-##"
                      v-mask="'(###) ###-##-##'"
                      maxlength="15"
                    />
                    <!--end::Input-->
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="phone" />
                      </div>
                    </div>
                  </div>
                  <!--end::Input group-->
                </div>
                <div class="col-md-3">
                  <!--begin::Input group-->
                  <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label
                      class="form-label fs-6 fw-bolder text-dark required"
                      >{{ $t("ogza.createCompany.department") }}</label
                    >
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      name="department"
                      autocomplete="off"
                      :placeholder="$t('ogza.createCompany.enterDepartment')"
                    />
                    <!--end::Input-->
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="department" />
                      </div>
                    </div>
                  </div>
                  <!--end::Input group-->
                </div>
              </div>
              <div class="row mb-0">
                <div class="col-md-12">
                  <!--begin::Input group-->
                  <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label
                      class="form-label fs-6 fw-bolder text-dark required" 
                      >{{ $t("ogza.createCompany.accountType") }}</label
                    >
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      name="accountType"
                      autocomplete="off"
                      :placeholder="$t('ogza.createCompany.selectAccountType')"
                    />
                    <!--end::Input-->
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="accountType" />
                      </div>
                    </div>
                  </div>
                  <!--end::Input group-->
                </div>
              </div>
              <div class="d-flex justify-content-center">
                <!--begin::Submit button-->
                <button
                  type="submit"
                  id="kt_account_profile_details_submit"
                  ref="submitButton"
                  class="btn btn-primary"
                >
                  <span class="indicator-label">{{
                    $t("common.button.create")
                  }}</span>
                  <span class="indicator-progress">
                    {{ $t("common.pleaseWait") }}
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span>
                  </span>
                </button>
                <!--end::Submit button-->
              </div>
            </div>
            <!--end::Card body-->
          </Form>
          <!--end::Form-->
        </div>
        <!--end::Body-->
      </div>
    </div>
  </div>
  <!--end::Row-->
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useI18n } from "vue-i18n";
import { CountryListModel } from "@/domain/country/country-list/model/CountryListModel";
import { mask } from "vue-the-mask";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { useStore } from "vuex";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";

export default defineComponent({
  name: "create-company",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const swalNotification = new SwalNotification();
    const countryList = ref<CountryListModel[]>([]);
    const countryController = store.state.ControllersModule.countryController;

    //Create form validation object
    const addCompanyForm = Yup.object().shape({
      companyName: Yup.string()
        .required(t("validators_error.required"))
        .label("companyName"),
      companyCategory: Yup.string()
        .required(t("validators_error.required"))
        .label("companyCategory"),
      managerName: Yup.string()
        .required(t("validators_error.required"))
        .label("managerName"),
      managerSurname: Yup.string()
        .required(t("validators_error.required"))
        .label("managerSurname"),
      email: Yup.string()
        .required(t("validators_error.required"))
        .email(t("validators_error.email"))
        .label("Email"),
      phone: Yup.string()
        .required(t("validators_error.required"))
        .label("phone"),
      department: Yup.string()
        .required(t("validators_error.required"))
        .label("department"),
      accountType: Yup.string()
        .required(t("validators_error.required"))
        .label("accountType"),
    });

    const getCountryList = async () => {
      const countryListModel: CountryListModel = {
        country: {},
        page: 1,
        pageSize: 50,
      };

      countryController
        .countryList(countryListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((country) => {
              countryList.value.push(country);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(() => {
      setCurrentPageTitle(t("ogza.createCompany.title"));
      getCountryList();
    });

    return {
      addCompanyForm,
      countryList,
    };
  },
  directives: { mask },
});
</script>
