
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useI18n } from "vue-i18n";
import { CountryListModel } from "@/domain/country/country-list/model/CountryListModel";
import { mask } from "vue-the-mask";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { useStore } from "vuex";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";

export default defineComponent({
  name: "create-company",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const swalNotification = new SwalNotification();
    const countryList = ref<CountryListModel[]>([]);
    const countryController = store.state.ControllersModule.countryController;

    //Create form validation object
    const addCompanyForm = Yup.object().shape({
      companyName: Yup.string()
        .required(t("validators_error.required"))
        .label("companyName"),
      companyCategory: Yup.string()
        .required(t("validators_error.required"))
        .label("companyCategory"),
      managerName: Yup.string()
        .required(t("validators_error.required"))
        .label("managerName"),
      managerSurname: Yup.string()
        .required(t("validators_error.required"))
        .label("managerSurname"),
      email: Yup.string()
        .required(t("validators_error.required"))
        .email(t("validators_error.email"))
        .label("Email"),
      phone: Yup.string()
        .required(t("validators_error.required"))
        .label("phone"),
      department: Yup.string()
        .required(t("validators_error.required"))
        .label("department"),
      accountType: Yup.string()
        .required(t("validators_error.required"))
        .label("accountType"),
    });

    const getCountryList = async () => {
      const countryListModel: CountryListModel = {
        country: {},
        page: 1,
        pageSize: 50,
      };

      countryController
        .countryList(countryListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((country) => {
              countryList.value.push(country);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(() => {
      setCurrentPageTitle(t("ogza.createCompany.title"));
      getCountryList();
    });

    return {
      addCompanyForm,
      countryList,
    };
  },
  directives: { mask },
});
